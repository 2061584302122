var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-wrapper--categories"},[_c('el-container',{staticClass:"wrapper-categories"},[_c('el-main',[_c('div',{staticClass:"container-categories"},[_c('div',{staticClass:"wrapper-section-header"},[_c('h2',{staticClass:"home-section-title"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.title`)))]),_vm._v(" "),_c('p',{staticClass:"home-section-description"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.description`)))])]),_vm._v(" "),_c('LazyLoading',{key:`categories`,attrs:{"loading":_vm.categories.length === 0,"loaded":_vm.lazy.categories},on:{"loaded":(e) => (_vm.lazy.categories = e)}},[(_vm.lazy.categories)?_c('Swiper',{ref:"swiperCategories",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.categories),function(item,i){return _c('SwiperSlide',{key:i},[_c('LadCard',{attrs:{"item":item,"base":_vm.base,"to":{
                  name: 'search-type',
                  params: {
                    type: _vm.$t('router.search_type.type.category'),
                    value: item.slug,
                    tab: _vm.$t('router.search_type.tab.tours'),
                  },
                }}})],1)}),_vm._v(" "),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_vm._v(" "),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2):_vm._e(),_vm._v(" "),_c('template',{slot:"css-skeleton"},[_c('LadCategorySwiperLoader')],1)],2)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }