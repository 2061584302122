<template>
  <div class="home-wrapper--categories">
    <el-container class="wrapper-categories">
      <el-main>
        <div class="container-categories">
          <div class="wrapper-section-header">
            <h2 class="home-section-title">{{ $t(`${base}.title`) }}</h2>
            <p class="home-section-description">{{
              $t(`${base}.description`)
            }}</p>
          </div>

          <LazyLoading
            :key="`categories`"
            :loading="categories.length === 0"
            :loaded="lazy.categories"
            @loaded="(e) => (lazy.categories = e)"
          >
            <Swiper
              v-if="lazy.categories"
              ref="swiperCategories"
              :options="swiperOption"
            >
              <SwiperSlide v-for="(item, i) in categories" :key="i">
                <LadCard
                  :item="item"
                  :base="base"
                  :to="{
                    name: 'search-type',
                    params: {
                      type: $t('router.search_type.type.category'),
                      value: item.slug,
                      tab: $t('router.search_type.tab.tours'),
                    },
                  }"
                />
              </SwiperSlide>

              <div slot="button-prev" class="swiper-button-prev"></div>
              <div slot="button-next" class="swiper-button-next"></div>
            </Swiper>

            <template slot="css-skeleton">
              <LadCategorySwiperLoader />
            </template>
          </LazyLoading>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CacheComponentMixin from '@/utils/mixin/cache-components'

export default {
  name: 'LadHomeCategories',
  components: {
    LadCard: () =>
      import(/* webpackChunkName: "lad-card" */ '@/components/card/index'),
    LadCategorySwiperLoader: () =>
      import(
        /* webpackChunkName: "lad-category-swiper-loader" */ '@/components/css-skeleton/category-swiper-loader'
      ),
  },
  mixins: [CacheComponentMixin],
  data() {
    return {
      base: 'home.categories',
      categories: [],
      current: 0,
      lazy: { categories: false },
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          // when window width is >= 800px
          800: {
            slidesPerView: 5,
            spaceBetween: 15,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: () => {
            const swiper = this.$refs.swiperCategories.$swiper

            if (!!swiper && swiper.activeIndex && !isNaN(swiper.activeIndex)) {
              this.current = swiper.activeIndex % this.categories.length
            }
          },
        },
      },
    }
  },
  watch: {
    'lazy.categories': {
      immediate: true,
      handler(newValue, oldValue) {
        if (process.server) return

        if (newValue) {
          this.getData()
        }
      },
    },
  },
  methods: {
    async getData() {
      try {
        const { categories, status } = await this.$axios.get(`/api/categories`)

        if (status === 'success') {
          const list = []

          categories.forEach((item) => {
            const label = item.replace('-', '_')

            list.push({
              slug: item,
              type: 'category',
              name: this.$t(`general.categories.${label}`),
              media: {
                type: 'image',
                data: {
                  srcset: `${require(`@/assets/images/categories/${label}.jpg`)} 1x`,
                  url: require(`@/assets/images/categories/${label}.jpg`),
                },
              },
            })
          })

          this.categories = list
        }
      } catch (error) {}
    },
  },
}
</script>

<style lang="scss">
.home-wrapper--categories {
  background-color: #ebeef2;
  padding: 2em 0 3em 0;

  .swiper-pagination {
    position: relative;
    margin-top: 20px;
    bottom: inherit;
  }

  @media screen and (max-width: 480px) {
    .swiper-wrapper {
      .swiper-slide {
        width: calc(100% - 100px);
        min-width: 220px;
      }
    }
  }
}
</style>
